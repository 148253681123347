<template>
<div>
  <header />
  <div id='wrapper'>
    <div id='content' style="text-align: center;">
      <h1 style="color: white;font-size: 30px;">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: inline; shape-rendering: auto;top: -30px" width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(50 50)">
            <g>
              <animateTransform attributeName="transform" type="rotate" values="0;45" keyTimes="0;1" dur="0.5813953488372093s" repeatCount="indefinite"></animateTransform>
              <path d="M29.491524206117255 -5.5 L37.491524206117255 -5.5 L37.491524206117255 5.5 L29.491524206117255 5.5 A30 30 0 0 1 24.742744050198738 16.964569457146712 L24.742744050198738 16.964569457146712 L30.399598299691117 22.621423706639092 L22.621423706639096 30.399598299691114 L16.964569457146716 24.742744050198734 A30 30 0 0 1 5.5 29.491524206117255 L5.5 29.491524206117255 L5.5 37.491524206117255 L-5.499999999999997 37.491524206117255 L-5.499999999999997 29.491524206117255 A30 30 0 0 1 -16.964569457146705 24.742744050198738 L-16.964569457146705 24.742744050198738 L-22.621423706639085 30.399598299691117 L-30.399598299691117 22.621423706639092 L-24.742744050198738 16.964569457146712 A30 30 0 0 1 -29.491524206117255 5.500000000000009 L-29.491524206117255 5.500000000000009 L-37.491524206117255 5.50000000000001 L-37.491524206117255 -5.500000000000001 L-29.491524206117255 -5.500000000000002 A30 30 0 0 1 -24.742744050198738 -16.964569457146705 L-24.742744050198738 -16.964569457146705 L-30.399598299691117 -22.621423706639085 L-22.621423706639092 -30.399598299691117 L-16.964569457146712 -24.742744050198738 A30 30 0 0 1 -5.500000000000011 -29.491524206117255 L-5.500000000000011 -29.491524206117255 L-5.500000000000012 -37.491524206117255 L5.499999999999998 -37.491524206117255 L5.5 -29.491524206117255 A30 30 0 0 1 16.964569457146702 -24.74274405019874 L16.964569457146702 -24.74274405019874 L22.62142370663908 -30.39959829969112 L30.399598299691117 -22.6214237066391 L24.742744050198738 -16.964569457146716 A30 30 0 0 1 29.491524206117255 -5.500000000000013 M0 -20A20 20 0 1 0 0 20 A20 20 0 1 0 0 -20" fill="#ffffff"></path>
            </g>
          </g>
        </svg> LINE Login oAuth
      </h1>
    </div>
  </div>
  <footer />
</div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    setTimeout(()=>{
      window.location = "/api/auth_line"
    }, 2000)
  },
}
</script>

<style lang="scss">
  body { background: url("https://i.pinimg.com/originals/65/9c/6f/659c6f3a1f1614d833ba197ff7032151.jpg"); } body, html { font-family: sans-serif; height: 100%; margin: 0; } header { height: 70px; } #app { align-items: center; display: flex; flex-direction: column; justify-content: center; text-align: center; /* 100% minus height of both header and footer */ height: calc(100% - (70px + 70px)); } #content { align-content: center; margin: auto; width: 400px; } footer { height: 70px; }
</style>
